import { themeTextMixin, getThemeStyleColor } from "model/themeUtils";
import { Theme } from "model/theme";
import {
  createStyle,
  getClassName,
  StyleVariant,
  TextVariant,
  ThemeStyleColorVariant
} from "@laba/react-common";

interface TitleContentDataHorizontalItemStyleProps {
  showLineBreaks: boolean;
}

interface TitleContentDataHorizontalItemStyleClassNames {
  root: string;
  principalTextItemList: string;
  secondaryTextItemList: string;
}

const useTitleContentDataHorizontalItemStyleStatic = createStyle(
  (theme: Theme) => ({
    root: {
      ...themeTextMixin(theme, TextVariant.Body2)
    },
    grayDarkColor: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "TitleContentDataHorizontalItem"
);

const useTitleContentDataHorizontalItemDynamicStyle = createStyle(
  (theme: Theme) => ({
    principalTextItemList: (
      props: TitleContentDataHorizontalItemStyleProps
    ) => ({
      ...themeTextMixin(
        theme,
        TextVariant.Subtitle2,
        undefined,
        props.showLineBreaks
      )
    }),
    secondaryTextItemList: (
      props: TitleContentDataHorizontalItemStyleProps
    ) => ({
      ...themeTextMixin(
        theme,
        TextVariant.Body2,
        undefined,
        props.showLineBreaks
      )
    })
  }),
  "TitleContentDataHorizontalItemDynamicStyle"
);

export const useTitleContentDataHorizontalItemStyle = (
  props: TitleContentDataHorizontalItemStyleProps
): TitleContentDataHorizontalItemStyleClassNames => {
  const staticClasses = useTitleContentDataHorizontalItemStyleStatic();
  const dynamicClasses = useTitleContentDataHorizontalItemDynamicStyle(props);

  return {
    root: getClassName(staticClasses.root, staticClasses.grayDarkColor),
    principalTextItemList: getClassName(
      dynamicClasses.principalTextItemList,
      staticClasses.grayDarkColor
    ),
    secondaryTextItemList: getClassName(
      dynamicClasses.secondaryTextItemList,
      staticClasses.grayDarkColor
    )
  };
};
