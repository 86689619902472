import { themeTextMixin, getThemeStyleColor } from "model/themeUtils";
import { Theme } from "model/theme";
import {
  createStyle,
  flexColumnMixin,
  getClassName,
  StyleVariant,
  TextVariant,
  ThemeStyleColorVariant
} from "@laba/react-common";

interface TitleContentDataVerticalItemStyleProps {
  showLineBreaks: boolean;
}

interface TitleContentDataVerticalItemStyleClassNames {
  root: string;
  principalTextItemList: string;
  secondaryTextItemList: string;
}

const useTitleContentDataVerticalItemStyleStatic = createStyle(
  (theme: Theme) => ({
    root: {
      ...themeTextMixin(theme, TextVariant.Body2),
      ...flexColumnMixin({})
    },
    grayDarkColor: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "TitleContentDataVerticalItem"
);

const useTitleContentDataVerticalItemDynamicStyle = createStyle(
  (theme: Theme) => ({
    principalTextItemList: (props: TitleContentDataVerticalItemStyleProps) => ({
      ...themeTextMixin(
        theme,
        TextVariant.Subtitle2,
        undefined,
        props.showLineBreaks
      )
    }),
    secondaryTextItemList: (props: TitleContentDataVerticalItemStyleProps) => ({
      ...themeTextMixin(
        theme,
        TextVariant.Body2,
        undefined,
        props.showLineBreaks
      )
    })
  }),
  "TitleContentDataVerticalItemDynamicStyle"
);

export const useTitleContentDataVerticalItemStyle = (
  props: TitleContentDataVerticalItemStyleProps
): TitleContentDataVerticalItemStyleClassNames => {
  const staticClasses = useTitleContentDataVerticalItemStyleStatic();
  const dynamicClasses = useTitleContentDataVerticalItemDynamicStyle(props);

  return {
    root: getClassName(staticClasses.root, staticClasses.grayDarkColor),
    principalTextItemList: getClassName(
      dynamicClasses.principalTextItemList,
      staticClasses.grayDarkColor
    ),
    secondaryTextItemList: getClassName(
      dynamicClasses.secondaryTextItemList,
      staticClasses.grayDarkColor
    )
  };
};
