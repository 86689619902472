import { ApiDate, getKeyObj } from "@laba/ts-common";
import { ContactPoint, Email, Phone } from "model/primitives/contactPoint";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { ResourceModel, ResourceType } from "model/primitives/resourceModel";
import { createHydratedMock } from "ts-auto-mock";
import { Identifier } from "model/primitives/identifier";
import {
  Code,
  CodeSystemCode,
  Coding
} from "model/resource/entities/codeSystem";
import { HealthcareService } from "model/resource/entities/healthcareService/healthcareService";
import { Patient } from "model/resource/person/patient/patient";
import { Practitioner } from "model/resource/person/practitioner/practitioner";
import {
  AppointmentDefinition,
  AppointmentDefinitionNotificationChannel
} from "model/resource/appointment/appointmentDefinition";
import { Location } from "model/resource/entities/location/location";
import {
  KnownScheduleType,
  Schedule,
  ScheduleNotificationConfig
} from "model/resource/schedule/schedule";
import { Model, OpenCode } from "model/primitives/model/model";
import { Attachment } from "model/primitives/attachment/attachment";
import { Product } from "../finance/product/product";
import { Organization } from "../entities/organization/organization";

export enum AppointmentNotificationConfigCodes {
  Create = "Create",
  EditPatientData = "EditPatientData",
  Cancel = "Cancel",
  Edit = "Edit",
  Admit = "Admit",
  Attend = "Attend",
  Finish = "Finish"
}

export type AppointmentNotificationConfigCode =
  OpenCode<AppointmentNotificationConfigCodes>;

export enum AppointmentStatus {
  Booked = "booked",
  Fulfilled = "fulfilled",
  Cancelled = "cancelled",
  Arrived = "arrived"
}

export enum AppointmentCancellationReasonCode {
  PractitionerCancelled = "PractitionerCancelled",
  EncounterCancelled = "EncounterCancelled"
}

export interface PatientAccessInfo {
  hash: string;
  link: string;
}

export interface NotificationChannel {
  code?: AppointmentDefinitionNotificationChannel;
  contactPoint?: ContactPoint;
}

export interface AppointmentRecurrenceTemplate {
  lastOccurrenceDate?: ApiDate;
  occurrenceCount?: number;
  weekInterval?: number;
}

export const NotificationChannelKey = getKeyObj<NotificationChannel>(
  createHydratedMock<NotificationChannel>()
);

export interface AppointmentPractitionerTeam extends Model {
  practitioner: ModelReference<Practitioner>;
}

export interface AppointmentNotificationConfig
  extends ScheduleNotificationConfig {
  phone?: Phone;
  email?: Email;
}

export const AppointmentNotificationConfigKey =
  getKeyObj<AppointmentNotificationConfig>(
    createHydratedMock<AppointmentNotificationConfig>()
  );

export interface AppointmentNotificationConfigObj {
  practitioner?: ScheduleNotificationConfig;
  patient?: AppointmentNotificationConfig;
}

export interface Appointment extends ResourceModel<ResourceType.Appointment> {
  status?: AppointmentStatus;
  appointmentDefinition?: ModelReference<AppointmentDefinition>;
  cancellationReason?: OpenCode<AppointmentCancellationReasonCode>;
  healthcareService?: ModelReference<HealthcareService>;
  consultationReason?: CodeSystemCode;
  startDate?: ApiDate;
  endDate?: ApiDate;
  minutesDuration?: number;
  comment?: string;
  patientInstruction?: string;
  patientAccessInfo?: PatientAccessInfo;
  patient?: ModelReference<Patient>;
  practitioner?: ModelReference<Practitioner>;
  notificationChannelList?: NotificationChannel[];
  overbooked?: boolean;
  identifier?: Identifier[];
  originatingAppointment?: ModelReference<Appointment>;
  recurrenceTemplate?: AppointmentRecurrenceTemplate;
  schedule?: ModelReference<Schedule>;
  admissionDate?: ApiDate;
  cancelDate?: ApiDate;
  fulfilledDate?: ApiDate;
  speciality?: Code[];
  practitionerTeam?: AppointmentPractitionerTeam[];
  location?: ModelReference<Location>;
  attachment?: Attachment[];
  tagList?: Coding[];
  originalPractitioner?: ModelReference<Practitioner>;
  lastEditor?: ModelReference<Practitioner>;
  lastEditDate?: ApiDate;
  notificationConfig?: AppointmentNotificationConfigObj;
  type?: KnownScheduleType;
  product?: ModelReference<Product>[];
  payer?: ModelReference<Organization>;
}

export const AppointmentKey = getKeyObj<Appointment>(
  createHydratedMock<Appointment>()
);
