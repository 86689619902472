import React, { FC } from "react";
import { NexupMarkdown } from "components/text/NexupMarkdown/NexupMarkdown";
import { useTitleContentDataVerticalItemStyle } from "./useTitleContentDataVerticalItemStyle";

export interface TitleContentDataVerticalItemProps {
  principalText?: string;
  secondaryText?: string;
  isMarkdown?: boolean;
  showLineBreaks?: boolean;
}

export const TitleContentDataVerticalItem: FC<
  TitleContentDataVerticalItemProps
> = ({
  principalText,
  secondaryText,
  isMarkdown = false,
  showLineBreaks = false
}) => {
  const classes = useTitleContentDataVerticalItemStyle({ showLineBreaks });
  return (
    <p className={classes.root}>
      {isMarkdown ? (
        <NexupMarkdown
          className={classes.principalTextItemList}
          text={principalText}
        />
      ) : (
        <span className={classes.principalTextItemList}>{principalText}</span>
      )}
      {isMarkdown ? (
        <NexupMarkdown
          className={classes.secondaryTextItemList}
          text={secondaryText}
        />
      ) : (
        <span className={classes.secondaryTextItemList}>{secondaryText}</span>
      )}
    </p>
  );
};
